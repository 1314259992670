<template>
  <div class="container">
    <div class="test-setting row">
      <div class="col-sm-5">
        <h3>{{ $t("toefl.test_settings") }}</h3>
        <hr class="hr" />
        <div class=" reading_listening-test mar">
          <h5>{{ $t("toefl.choose_mode") }}:</h5>
          <el-radio v-model="mode" label="0">
            {{ $t("toefl.default") }}
          </el-radio>
          <el-radio v-model="mode" label="1">
            {{ $t("toefl.strict") }}
          </el-radio>
        </div>
        <div class="show-mode">
          <img v-show="mode === '0'" src="./../../../assets/TOEFL.png" />
          <img v-show="mode === '1'" src="./../../../assets/TOEFLReal.png" />
        </div>
      </div>

      <div class="col-sm-7">
        <h3>{{ $t("toefl.system_check") }}</h3>
        <hr class="hr" />

        <div class="mar">
          <h5>
            <span class="text-success" v-show="chrome && pc">
              <i class="fas fa-check-square"></i>
            </span>
            <span class="text-danger" v-show="!chrome || !pc">
              <i class="fas fa-times-circle"></i>
            </span>
            {{ $t("toefl.device_and_browser") }}:
            <small class="text-success" v-if="ischrome">
              {{ $t("toefl.success") }}
            </small>
            <small class="text-danger" v-else>
              請使用Chrome瀏覽和電腦進行作答。
            </small>
          </h5>
        </div>

        <hr class="hr" />
        <div class="check-audio-files mar">
          <h5>
            <span class="text-success">
              <i class="fas fa-check-square"></i>
            </span>
            {{ $t("toefl.loading_files") }}:
            <small class="text-success">
              {{ $t("toefl.success") }}
            </small>
          </h5>
        </div>

        <div
          v-show="type === 'mock' || type === 'quick' || type === 'speaking'"
        >
          <hr class="hr" />
          <div class="check-audio-files mar">
            <h5>
              <span class="text-success" v-show="canRecord">
                <i class="fas fa-check-square"></i>
              </span>
              <span class="text-danger" v-show="!canRecord">
                <i class="fas fa-times-circle"></i>
              </span>
              {{ $t("toefl.load_recording") }}:
              <small class="text-success" v-if="canRecord">
                {{ $t("toefl.success") }}
              </small>
              <div v-else>
                <small class="text-danger">
                  <el-popover placement="bottom" width="300" trigger="hover">
                    <img
                      style="display:block;width:100%"
                      src="./../../../assets/check_mic.png"
                      alt=""
                    />
                    <span slot="reference">
                      {{ $t("toefl.check_mic")
                      }}<i class="fa fa-question-circle"></i>
                    </span>
                  </el-popover>
                </small>
              </div>
            </h5>
          </div>
        </div>
        <div v-show="type !== 'reading'">
          <hr class="hr" />
          <div class="mar">
            <h5>
              <el-checkbox v-model="output"></el-checkbox>
              {{ $t("toefl.sound_output") }}:
              <el-button
                v-if="testAudio"
                icon="el-icon-video-play"
                @click="playAudioTest('audio_listening')"
              >
                <span>
                  {{ $t("toefl.click_to_listen") }}
                </span>
              </el-button>
              <el-button
                v-else
                icon="el-icon-video-pause"
                @click="playAudioTest('audio_listening')"
              >
                <span>
                  {{ $t("toefl.click_to_pause") }}
                </span>
              </el-button>
              <small class="text-success" v-if="output">
                {{ $t("toefl.success") }}
              </small>
            </h5>

            <audio
              id="audio_listening"
              src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/timer/bugle.mp3"
              @ended="testAudio = true"
            ></audio>
            <span class="bugle_stop pointer text-success" style="display: none">
              <i class="control_audio fas fa-volume-up"></i>
            </span>
          </div>
        </div>
        <div
          v-show="type === 'mock' || type === 'quick' || type === 'speaking'"
        >
          <hr class="hr" />
          <div class="speaking-test mar">
            <h5>
              <el-checkbox v-model="input" v-if="canRecord"></el-checkbox>
              <span class="text-danger" v-else>
                <i class="fas fa-times-circle"></i>
              </span>
              {{ $t("toefl.sound_input") }}:
              <div v-if="canRecord" style="display:inline-block">
                <el-button
                  @click="readyOriginal"
                  type=""
                  icon="el-icon-microphone"
                >
                  <span v-if="isVoice">{{ $t("toefl.click_to_pause") }}</span>
                  <span v-else>{{ $t("toefl.test_record") }}</span>
                </el-button>
                <el-button v-show="isVoice" @click="readyOriginal">
                  <i class="fas fa-record-vinyl"></i>
                  {{ $t("toefl.recording") }}
                </el-button>
                <el-button
                  v-show="isComplete"
                  @click="playAudio('audio')"
                  type="primary"
                  icon="el-icon-video-play"
                >
                  {{ $t("toefl.click_to_listen") }}
                </el-button>
                <small class="text-success" v-if="input">
                  {{ $t("toefl.success") }}
                </small>
              </div>
              <div v-else>
                <small class="text-danger">
                  <el-popover placement="bottom" width="300" trigger="hover">
                    <img
                      style="display:block;width:100%"
                      src="./../../../assets/check_mic.png"
                      alt=""
                    />
                    <span slot="reference">
                      {{ $t("toefl.check_mic")
                      }}<i class="fa fa-question-circle"></i>
                    </span>
                  </el-popover>
                </small>
              </div>
            </h5>
          </div>
        </div>
      </div>
      <audio id="audio" :src="audio_src" type="audio/wav"></audio>
      <div class="col-sm-12"><hr class="hr" /></div>
      <div class="col-sm-12" style="margin-bottom:20px">
        <el-alert
          v-if="canStart"
          :title="
            $t('toefl.Please check all the boxes in System Check above first')
          "
          type="warning"
          show-icon
          :closable="false"
        >
        </el-alert>
      </div>

      <div class="col-sm-12 text-center">
        <div v-if="!canStart">
          <el-button @click="startTest" type="success" :disabled="canStart">
            {{ $t("courses.start") }}
          </el-button>
        </div>
        <div v-else>
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              $t('toefl.Please check all the boxes in System Check above first')
            "
            placement="top"
          >
            <el-button type="info">
              {{ $t("courses.start") }}
            </el-button>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import HZRecorder from "@/views/toefl/utils/recorder.js";
import ToeflMixin from "@/mixins/toefl.js";
import Toefl from "@/apis/toefl.js";

export default {
  metaInfo() {
    return {
      title: "TOEFL Set Test - TOEFL Mocks"
    };
  },
  components: {},
  mixins: [ToeflMixin],
  props: [],
  data() {
    return {
      mode: "0",
      isComplete: false,
      isVoice: false,
      audio_src: {},
      style: true,
      recorder: {},
      // canStart: true,
      canRecord: false,
      input: false,
      output: false,
      testAudio: true,
      chrome: this.isChrome(),
      pc: this.isPC()
    };
  },
  computed: {
    ischrome() {
      return this.isChrome();
    },
    title() {
      return this.$route.query.title;
    },
    chapter_id() {
      return this.$route.query.chapter_id;
    },
    type() {
      return this.$route.query.testType;
    },
    version() {
      return parseInt(this.$route.query.version);
    },
    canStart() {
      let canStart = true;
      if (this.canRecord && this.input && this.output && this.isChrome) {
        canStart = false;
      }
      return canStart;
    }
  },
  watch: {},

  mounted() {
    if (
      this.type === "mock" ||
      this.type === "quick" ||
      this.type === "speaking" ||
      this.type === undefined
    ) {
      this.resetAudio();
      if (this.type === undefined) {
        this.canRecord = true;
        this.input = true;
      }
    } else {
      this.canRecord = true;
      this.input = true;
    }
    if (this.type === "reading") {
      this.output = true;
    }
  },

  methods: {
    //重置录音
    resetAudio() {
      let vm = this;
      this.$nextTick(() => {
        try {
          window.AudioContext =
            window.AudioContext || window.webkitAudioContext;
          navigator.getUserMedia =
            navigator.getUserMedia || navigator.webkitGetUserMedia;
          window.URL = window.URL || window.webkitURL;
        } catch (e) {
          alert("No web audio support in this browser!");
        }
        navigator.getUserMedia(
          { audio: true },
          function(stream) {
            vm.recorder = new HZRecorder(stream);
            vm.canRecord = true;
            // console.log("初始化完成");
          },
          function() {}
        );
      });
    },
    //录音开始和结束
    readyOriginal() {
      // let recorder = this.recorder;
      if (!this.isVoice) {
        this.recorder && this.recorder.start();
        this.isVoice = true;
        this.isComplete = false;
      } else {
        this.isVoice = false;
        this.recorder && this.recorder.stop();
        this.isComplete = true;
        this.audio_src = window.URL.createObjectURL(this.recorder.getBlob());
      }
    },
    //播放录音
    playAudio(id) {
      let audio = document.getElementById(id);
      audio.play();
      this.resetAudio();
    },
    playAudioTest(id) {
      let audio = document.getElementById(id);

      if (this.testAudio) {
        this.testAudio = false;
        audio.currenTime = 0;
        audio.play();
      } else {
        this.testAudio = true;
        audio.pause();
      }
    },
    //开始考试
    async startTest() {
      let examId = 0;

      // 快速
      if (
        this.chapter_id === undefined &&
        this.type === "quick" &&
        this.title !== undefined
      ) {
        examId = await Toefl.getExamId({
          title: this.title,
          is_new: this.version,
          is_quick_mock: 1
        });
      }

      //单元
      if (
        this.chapter_id === undefined &&
        this.type !== "quick" &&
        this.type !== "mock" &&
        this.title !== undefined
      ) {
        examId = await Toefl.getExamId({
          title: this.title,
          is_new: this.version,
          type: this.type
        });
      }
      // 单篇
      if (
        this.title === undefined &&
        this.chapter_id !== undefined &&
        this.type === undefined
      ) {
        examId = await Toefl.getExamId({
          chapter_id: this.chapter_id,
          is_new: this.version
        });
      }
      //整份
      if (
        this.title !== undefined &&
        this.chapter_id === undefined &&
        this.type === "mock"
      ) {
        examId = await Toefl.getExamId({
          title: this.title,
          is_new: this.version
        });
      }
      this.routerPush({
        name: "ToeflTest",
        query: {
          id: examId,
          mode: this.mode
        }
      });
    }
  }
};
</script>

<style scoped>
.test-setting {
  margin: 20px 0;
}
.hr {
  border-bottom: 2px solid #43a06b;
}
.show-mode {
  padding: 5px;
  border: 1px solid #ccc;
}
.show-mode img {
  width: 100%;
}
.mar >>> .el-checkbox__inner {
  border: 1px solid #f56c6c;
}
.mar >>> .el-checkbox__input.is-checked .el-checkbox__inner {
  border: 1px solid #42a16a;
}
h5 > span {
  font-size: 16px;
}
</style>

/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
export default {
  methods: {
    // check if it's Chrome browser
    isChrome() {
      var isChromium = window.chrome;
      var winNav = window.navigator;
      var vendorName = winNav.vendor;
      var isOpera = typeof window.opr !== "undefined";
      var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
      var isIOSChrome = winNav.userAgent.match("CriOS");

      if (isIOSChrome) {
        // is Google Chrome on IOS
        return true;
      } else if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
      ) {
        // is Google Chrome
        return true;
      } else {
        // not Google Chrome
        return false;
      }
    },
    isPC() {
      let userAgentInfo = navigator.userAgent;
      let Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      let flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    //获取答案
    getOption(answer, isCorrect, canswer) {
      let option = answer;
      if (isCorrect && canswer.indexOf(answer) > -1) {
        option = `<span style="color:green"> ${option} </span>`;
      } else {
        option = `<span style="color:red"> ${option} </span>`;
      }
      return option;
    },
    getCorrectAnswer(answer) {
      let correctAnswer = "";
      if (answer.length == 1) {
        answer[0].forEach(item => {
          correctAnswer += `<span style="color:green"> ${item} </span>`;
        });
      } else if (answer.length == 2) {
        answer[0].forEach(item => {
          correctAnswer += `<span style="color:green"> ${item} </span>`;
        });
        correctAnswer = correctAnswer + ",";
        answer[1].forEach(item => {
          correctAnswer += `<span style="color:green"> ${item} </span>`;
        });
      }
      return correctAnswer;
    },
    getMyAnswer(answer, canswer, is_correct) {
      let myAnswer = "";
      let isCorrect = false;
      if (is_correct == 1) isCorrect = true;
      answer.forEach(item => {
        myAnswer += this.getOption(item, isCorrect, canswer[0]);
      });
      return myAnswer;
    }
  }
};
